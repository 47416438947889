import * as React from 'react'

import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'

import { BannerPage, Container, Platforms, Seo } from '../../components/index'

const Pages = ({ data, pageContext }) => {
  const { mdx } = data
  const { frontmatter } = mdx

  const { title, description } = frontmatter

  return (
    <>
      <Seo title={title} description={description} pathname={pageContext.slug} isLanding />
      <BannerPage
        title={title}
        description={description}
        component={
          <div className="uppercase">
            Also listen on <Platforms />
          </div>
        }
      />
      <Container>
        <div className="max-w-2xl mx-auto my-20 markdown">
          <MDXRenderer>{mdx.body}</MDXRenderer>
        </div>
      </Container>
    </>
  )
}

export default Pages

export const query = graphql`
  query CurrentPagesQuery($slug: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      fileAbsolutePath
      frontmatter {
        title
        description
      }
      body
      fields {
        slug
      }
    }
  }
`
